<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>

      <CRow class="justify-content-center">
        <CCol md="6">
            <div class="logo"><img src="@/assets/images/cropped-favicon_las-1.png"></div>
        </CCol>
      </CRow>
      <CRow class="justify-content-center">

        <CCol md="6">
          <CCardGroup>
            <CCard class="shadow-lg">
              <CCardBody>
                <form class="card-body" @submit="handlerSubmit">
                    <div class="form-group">
                        <!-- <label for="username">E-mail</label> -->
                        <input
                            type="text"
                            class="form-control"
                            id="username"
                            v-model="user.identifier"
                            :class="{
                                'border-danger': isEmptyIdentifier
                            }"
                            placeholder="Usuário"
                            autocomplete="off"
                        />
                        <div
                            class="error text-danger empty-username"
                            v-if="isEmptyIdentifier"
                        >
                            Este campo é de preenchimento obrigatório.
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- <label for="password">Senha:</label> -->
                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            v-model="user.password"
                            :class="{
                                'border-danger': isEmptyPassword
                            }"
                            placeholder="Senha"
                        />
                        <div
                            class="error text-danger empty-password"
                            v-if="isEmptyPassword"
                        >
                            Este campo é de preenchimento obrigatório.
                        </div>
                    </div>

                    <div class="alert alert-danger" v-if="getAuthError">
                        Erro ao fazer o login.
                        <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            @click="closeError"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <button
                        id="handlerSubmit"
                        type="submit"
                        class="btn btn-primary btn-access mt-2"
                        v-if="!isLoading"
                    >
                        Entrar
                    </button>
                       
                    <div
                        class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                        v-else
                    >
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </form>
                <div style="display:block;text-align:center">
                    <small> Ainda não tem cadastro? <a href="/cadastro">Clique aqui.</a></small> 
                </div>
                
              </CCardBody>
            </CCard>
           
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>


<script>

import { LoginMixin } from './Login-mixin'
export default {
  name: 'Login',
  mixins: [LoginMixin]
}
</script>
<style lang="scss" scoped>

.logo{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom:30px;
    img {
        width:135px;
    }
}
button {
    color:#fff !important;
    width:100%;
}
</style>
